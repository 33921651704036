import React, { useEffect, useState } from 'react';
import { Typography, Button, Space } from 'antd';
import { useNavigate } from 'react-router-dom';

function UploadStatus() {
  const [uploadIds, setUploadIds] = useState([]);
  const [statuses, setStatuses] = useState({});
  const [urls, setUrls] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const storedIds = JSON.parse(localStorage.getItem('uploadIds')) || [];
    setUploadIds(storedIds);

    const checkStatuses = async () => {
      const statusPromises = storedIds.map(async (uploadId) => {
        const response = await fetch(`/.netlify/functions/checkStatus?uploadId=${uploadId}`);
        const { status } = await response.json();
        return { uploadId, status };
      });

      const results = await Promise.all(statusPromises);
      const statusMap = results.reduce((acc, { uploadId, status }) => {
        acc[uploadId] = 'Ready'; // status; // TODO: fix when ML model is connected
        return acc;
      }, {});

      setStatuses(statusMap);
    };

    const fetchUrls = async () => {
      const urlPromises = storedIds.map(async (uploadId) => {
        const response = await fetch(`/.netlify/functions/getSignedUrl?uploadId=${uploadId}`);
        const { url } = await response.json();
        return { uploadId, url };
      });

      const results = await Promise.all(urlPromises);
      const urlMap = results.reduce((acc, { uploadId, url }) => {
        acc[uploadId] = url;
        return acc;
      }, {});

      setUrls(urlMap);
    };

    checkStatuses();
    fetchUrls();
    const intervalId = setInterval(() => {
      checkStatuses();
      fetchUrls();
    }, 5 * 60 * 1000); // Check every 5 minutes

    return () => clearInterval(intervalId);
  }, []);

  const handleViewFile = (uploadId) => {
    const viewerUrl = `/viewer/${uploadId}`;
    window.open(viewerUrl, '_blank');
  };

  return (
    <div>
      <Typography.Title level={4}>Latest Uploads</Typography.Title>
      {uploadIds.slice().reverse().map((id) => ( // Reverse the order of uploadIds
        <Space key={id} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
          <Typography.Text>{id}</Typography.Text>
          {statuses[id] === 'Ready' ? (
            <Button type="primary" onClick={() => handleViewFile(id)}>
              View Model
            </Button>
          ) : (
            <Button type="default" disabled style={{ backgroundColor: 'orange', color: 'white' }}>
              Processing
            </Button>
          )}
        </Space>
      ))}
    </div>
  );
}

export default UploadStatus;



// import React, { useEffect, useState } from 'react';
// import { Typography } from '@mui/material';

// function UploadStatus() {
//   const [uploadIds, setUploadIds] = useState([]);
//   const [statuses, setStatuses] = useState({});

//   useEffect(() => {
//     const storedIds = JSON.parse(localStorage.getItem('uploadIds')) || [];
//     setUploadIds(storedIds);

//     const checkStatuses = async () => {
//       const statusPromises = storedIds.map(async (uploadId) => {
//         const response = await fetch(`/.netlify/functions/checkStatus?uploadId=${uploadId}`);
//         const { status } = await response.json();
//         return { uploadId, status };
//       });

//       const results = await Promise.all(statusPromises);
//       const statusMap = results.reduce((acc, { uploadId, status }) => {
//         acc[uploadId] = status;
//         return acc;
//       }, {});

//       setStatuses(statusMap);
//     };

//     checkStatuses();
//     const intervalId = setInterval(checkStatuses, 5 * 60 * 1000); // Check every 5 minutes

//     return () => clearInterval(intervalId);
//   }, []);

//   return (
//     <div>
//       <Typography variant="h6">Upload Status</Typography>
//       {uploadIds.map((id) => (
//         <Typography key={id}>
//           Upload ID: {id} - Status: {statuses[id] || 'Checking...'}
//         </Typography>
//       ))}
//     </div>
//   );
// }

// export default UploadStatus;
