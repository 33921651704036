import React, { useState } from 'react';
import { Button, Typography, Card } from 'antd';
import DragAndDrop from './components/DragAndDrop';
import useFileSelection from './hooks/useFileSelection';

function UploadForm() {
  const [uploadStatus, setUploadStatus] = useState('');
  const [selectedFiles, addFile, removeFile] = useFileSelection();

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Form submitted');
    console.log('Selected files:', selectedFiles);

    if (selectedFiles.length > 10) {
      alert('Please upload no more than 10 images.');
      return;
    }

    try {
      const response = await fetch('/.netlify/functions/upload', {
        method: 'POST',
      });
      const { uploadId, signedUrls } = await response.json();

      const uploadPromises = selectedFiles.map((file, index) => {
        console.log('Uploading file:', file.name);
        return fetch(signedUrls[index], {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/octet-stream',
          },
          body: file,
        });
      });

      await Promise.all(uploadPromises);

      let uploadIds = JSON.parse(localStorage.getItem('uploadIds')) || [];
      uploadIds.push(uploadId);
      localStorage.setItem('uploadIds', JSON.stringify(uploadIds));

      setUploadStatus(`Upload successful! New upload ID: ${uploadId}`);
      
      // Refresh the page to update the previous uploads
      setTimeout(() => {
        window.location.reload();
      }, 2000); // 2000 milliseconds = 2 seconds
    } catch (error) {
      console.error('Upload failed:', error);
      setUploadStatus('Upload failed. Please try again.');
    }
  };

  return (
    <div style={{ margin: '1%' }}>
      <Card
        style={{ margin: 'auto', width: '80%' }}
        actions={[
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={selectedFiles.length === 0}
          >
            Upload Images
          </Button>,
        ]}
      >
        <DragAndDrop addFile={addFile} removeFile={removeFile} />
        {uploadStatus && <Typography.Text>{uploadStatus}</Typography.Text>}
      </Card>
    </div>
  );
}

export default UploadForm;



// import React, { useState } from 'react';
// import { Button, Input, Typography } from '@mui/material';

// function UploadForm() {
//   const [files, setFiles] = useState([]);
//   const [uploadStatus, setUploadStatus] = useState('');

//   const handleFileChange = (event) => {
//     setFiles(event.target.files);
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     if (files.length > 10) {
//       alert('Please upload no more than 10 images.');
//       return;
//     }

//     try {
//       // Request signed URLs from the Netlify function
//       const response = await fetch('/.netlify/functions/upload', {
//         method: 'POST',
//       });
//       const { uploadId, signedUrls } = await response.json();

//       // Upload files directly to Google Cloud Storage using the signed URLs
//       const uploadPromises = Array.from(files).map((file, index) => {
//         return fetch(signedUrls[index], {
//           method: 'PUT',
//           headers: {
//             'Content-Type': 'application/octet-stream',
//           },
//           body: file,
//         });
//       });

//       await Promise.all(uploadPromises);

//       // Store the upload ID in local storage
//       let uploadIds = JSON.parse(localStorage.getItem('uploadIds')) || [];
//       uploadIds.push(uploadId);
//       localStorage.setItem('uploadIds', JSON.stringify(uploadIds));

//       setUploadStatus(`Upload successful! Upload ID: ${uploadId}`);
//     } catch (error) {
//       console.error('Upload failed:', error);
//       setUploadStatus('Upload failed. Please try again.');
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit} encType="multipart/form-data">
//       <Input
//         type="file"
//         id="imageInput"
//         name="images"
//         accept="image/jpeg"
//         multiple
//         onChange={handleFileChange}
//       />
//       <Button type="submit" variant="contained" color="primary">
//         Upload Images
//       </Button>
//       {uploadStatus && <Typography>{uploadStatus}</Typography>}
//     </form>
//   );
// }

// export default UploadForm;
