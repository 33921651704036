import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UploadForm from './UploadForm';
import UploadStatus from './UploadStatus';
import Viewer from './Viewer';
import { Container, Typography } from '@mui/material';

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <div
              style={{
                margin: '10% auto', // Default margins for large screens
                maxWidth: '600px', // Limit the width to avoid excessive stretching
                padding: '0 10%', // Provide side padding for smaller screens
              }}
            >
              <Container>
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                  <Typography variant="h4" component="h1" gutterBottom>
                    Generative 3D Image Processor
                  </Typography>
                </div>
                <Typography
                  variant="body1"
                  style={{ textAlign: 'center', color: 'gray', marginBottom: '20px' }}
                >
                  Create a Generative 3D model from images. Usage - upload the images via the interface provided. A job ID will be generated and the 3D model will be ready for inspection below by the click of a button.
                </Typography>
                <UploadForm />
                <UploadStatus />
              </Container>
            </div>
          }
        />
        <Route path="/viewer/:uploadId" element={<Viewer />} />
      </Routes>
    </Router>
  );
}


export default App;



// import React from 'react';
// import UploadForm from './UploadForm';
// import UploadStatus from './UploadStatus';
// import { Container, Typography } from '@mui/material';

// function App() {
//   return (
//     <Container>
//       <Typography variant="h4" component="h1" gutterBottom>
//         Image Upload
//       </Typography>
//       <UploadForm />
//       <UploadStatus />
//     </Container>
//   );
// }

// export default App;
