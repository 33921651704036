import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as GaussianSplats3D from '@mkkellogg/gaussian-splats-3d'; // Adjust the import path as needed

function Viewer() {
  const { uploadId } = useParams();

  useEffect(() => {
    async function loadScene() {
      try {
        // Fetch the signed URL from the Netlify function
        const response = await fetch(`/.netlify/functions/getSignedUrl?uploadId=${uploadId}`);
        const { url } = await response.json();

        const viewer = new GaussianSplats3D.Viewer({
          'cameraUp': [0, -1, -0.54],
          'initialCameraPosition': [-3.15634, -0.16946, -0.51552],
          'initialCameraLookAt': [1.52976, 2.27776, 1.65898],
          'sphericalHarmonicsDegree': 2
        });

        // Set the clear color to black
        viewer.renderer.setClearColor(0x000000, 1);

        // Use the signed URL to load the scene
        viewer.addSplatScene(url, {
          'progressiveLoad': false
        })
        .then(() => {
          viewer.start();
        })
        .catch(error => {
          console.error('Error loading scene:', error);
          document.body.innerHTML = '<h1>Error loading scene: the backend service is currently disabled</h1>';
        });
      } catch (error) {
        console.error('Error fetching signed URL:', error);
        document.body.innerHTML = '<h1>Error fetching signed URL</h1>';
      }
    }

    if (uploadId) {
      loadScene();
    } else {
      document.body.innerHTML = '<h1>Error: Upload ID is missing</h1>';
    }
  }, [uploadId]);

  return (
    <div style={{ width: '100vw', height: '100vh', backgroundColor: '#000', margin: 0, position: 'fixed', top: 0, left: 0 }}>
      {/* The 3D scene will be rendered here */}
    </div>
  );
}

export default Viewer;
